import React from 'react'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '~/constants/routes'
import { useLocale } from '~/hooks/useLocale'
import type { ValuesByLocale } from '~/i18n/config'
import ParallaxSection from '../ParallaxSection'
import FollowUpImageEN from './en/follow-up.jpg'
import FollowUpImageFR from './fr/follow-up.jpg'

const FOLLOW_UP_IMAGES: ValuesByLocale<string> = {
  fr: FollowUpImageFR,
  en: FollowUpImageEN
}

const HomeFollowUp = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation()
  const locale = useLocale()

  return (
    <ParallaxSection
      ref={ref}
      ParallaxProps={{
        translateY: [15, -15],
        className: 'max-w-xl md:pt-10'
      }}
      ContentProps={{
        className: 'w-full justify-end 2xl:pt-80 md:items-end'
      }}
      title={t('common:theFollowUp')}
      className="bg-wood-texture bg-cover bg-scroll bg-center bg-no-repeat"
      stepNumber={2}
      imgProps={{
        src: FOLLOW_UP_IMAGES[locale],
        alt: t('common:theFollowUp'),
        className: 'object-right'
      }}
      descriptions={[
        t('home:followUp.description'),
        t('home:followUp.moreDescription')
      ]}
      learnMoreTo={ROUTES.followup}
    />
  )
})

export default HomeFollowUp
